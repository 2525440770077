import './UserLanguage.scss';
import { RadioButton } from '@carbon/react';
import React, { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { CustomMenu } from 'src/modules/common/components/CustomMenu';
import { CustomMenuItem } from 'src/modules/common/components/CustomMenuItem';
import { GlobeIcon } from 'src/modules/common/icons/GlobeIcon';
import { CONFIG_LANGUAGE_SWITCH } from 'src/modules/config/actions/ConfigActions';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';

export const UserLanguage = memo(() => {
  const intl = useIntl();

  const language = useSelector(getLanguage);

  const switchLanguage = useAction(CONFIG_LANGUAGE_SWITCH.request);

  const handleLanguageSelectEN = useCallback(() => {
    switchLanguage('en');
  }, [switchLanguage]);

  const handleLanguageSelectDE = useCallback(() => {
    switchLanguage('de');
  }, [switchLanguage]);

  const handleLanguageSelectZH = useCallback(() => {
    switchLanguage('zh');
  }, [switchLanguage]);

  return (
    <CustomMenu
      className="bp-language"
      flipped={true}
      iconDescription={intl.formatMessage({ id: 'common/header/language' })}
      tooltipPosition="bottom"
      renderIcon={() => (
        <div className="bp-language__icon">
          <GlobeIcon/>
        </div>
      )}
    >
      <CustomMenuItem
        onClick={handleLanguageSelectEN}
        itemText={(
          <RadioButton
            labelText={intl.formatMessage({ id: 'header/user/language/en' })}
            value="en"
            checked={language === 'en'}
            id="en"
          />
        )}
        hasDivider={true}
      />

      <CustomMenuItem
        onClick={handleLanguageSelectDE}
        itemText={(
          <RadioButton
            labelText={intl.formatMessage({ id: 'header/user/language/de' })}
            value="de"
            checked={language === 'de'}
            id="de"
          />
        )}
        hasDivider={true}
      />

      <CustomMenuItem
        onClick={handleLanguageSelectZH}
        itemText={(
          <RadioButton
            labelText={intl.formatMessage({ id: 'header/user/language/zh' })}
            value="zh"
            checked={language === 'zh'}
            id="zh"
          />
        )}
        hasDivider={true}
      />
    </CustomMenu>
  );
});
