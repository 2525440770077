import './OutputPage.scss';
import clsx from 'clsx';
import React from 'react';
import { OutputPagination } from 'src/modules/output/views/OutputPagination';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly language: Language;
  readonly children: React.ReactNode;
  readonly showPageNumber: boolean;
};

export const OutputPage = React.forwardRef((
  {
    language,
    children,
    showPageNumber,
  }: Props,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement => (
  <div
    ref={forwardedRef}
    className={clsx({
      'bp-output-page': true,
      'bp-output-page--zh': language === 'zh',
    })}
  >
    <div className="bp-output-page__body">
      {children}
    </div>

    {showPageNumber && (
      <div className="bp-output-page__pager">
        <OutputPagination/>
      </div>
    )}
  </div>
));
