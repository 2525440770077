import { Language } from 'src/types/common/Language';

export const TERMS_AND_PRIVACY_LINK_MAP: Readonly<Record<Language, string>> = {
  de: 'https://www.belimo.com/ch/de_CH/privacy',
  en: 'https://www.belimo.com/ch/en_US/privacy',
  zh: 'https://www.belimo.com/cn/zh_CN/privacy',
};

export const IMPRINT_LINK_MAP: Readonly<Record<Language, string>> = {
  de: 'https://www.belimo.com/ch/de_CH/imprint',
  en: 'https://www.belimo.com/ch/en_US/imprint',
  zh: 'https://www.belimo.com/cn/zh_CN/imprint',
};
